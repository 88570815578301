import React, {useState} from 'react';
// import logo from './logo.svg';
import Cookies from 'universal-cookie';
import './css/tailwind.css'

// import LogoutButton from './components/logoutButton';
import PanelView from './views/PanelView';
import AuthView from './views/AuthView';


const cookies = new Cookies();

function App() {
  const [auth, setAuth] = useState(false);
  // const [login, setLogin] = useState(true);
  const [navToggled, setNavToggled] = useState(false);
  const [openNote, setOpenNote] = useState("/api/user/home_page")
  const [cookieStore, setCookieStore] = useState("")
  
  var storedCookie = ""

  const doSetCookie = (cookie: string, val: string) => {
    cookies.set(cookie, val, { path: '/', secure: true })
  }

  const doGetCookie = (cookie: string) => {
    return cookies.get(cookie)
  }

  const setCookieVal = (cookie: string) => {
    console.log("app.tsx sets cookie stored val:", cookie)
    storedCookie = cookie
    setCookieStore(cookie)
    console.log("app.tsx:", storedCookie)
  }

  let handleState = (value: boolean) => {
    console.log(cookies.get("x-access-token"), value);
    // || cookies.get("x-access-token") != undefined)
    if (!!value || (cookies.get("x-access-token") !== "")) {
      console.log("got to here")
      if (cookieStore == ""){
        setCookieStore(cookies.get("x-access-token"));
      }
      // <ReactEditor cookie={cookies} NoteUrl={openNote} title={"Home Page"}/>

      return (
        <PanelView cookie={cookies} cookieStore={cookieStore} getCookie={doGetCookie} setCookie={doSetCookie} setCookieStore={setCookieStore} setAuth={setAuth}></PanelView>
      )
    }
    else {
      return (
        <div style={
          {
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            flexDirection: "column",
            alignItems: "center"
          }}>
          <AuthView cookie={cookies} setCookie={setCookieStore} setAuth={setAuth} pushCookie={setCookieVal} />
        </div>
      )
    }
  }

  return (
    <div>
      {handleState(auth)}
    </div>
  );
}


export default App;



// if (!!login) {
      // return <form method='post' action='http://localhost:3333' onSubmit={(e: React.SyntheticEvent) => {
      //   console.log("Beginning Request")
      //   e.preventDefault();
      //   console.log(e)
      //   let target = {
      //     username: e.target
      //   }


      //   var formdata = new FormData();
      //   formdata.append("username", username);
      //   formdata.append("password", password);

      //   var requestOptions = {
      //     method: 'POST',
      //     body: formdata,
      //     redirect: 'follow'
      //   };

      //   fetch("http://localhost:3333/auth/login", {
      //     credentials: "same-origin",
      //     method: 'POST',
      //     body: formdata,
      //     redirect: 'follow'
      //   })
      //     .then(response => response.json())
      //     .then(result => {console.log(result); cookies.set('x-access-token', result.replaceAll("%22",""), { path: '/' }); setAuth(true)})
      //     .catch(error => console.log('error', error));

      //     fetch("http://localhost:3333/api/v1/auth/session", {
      //     credentials: "same-origin",
      //     method: 'POST',
      //     body: formdata,
      //     redirect: 'follow'
      //   })
      //     .then(response => response.text())
      //     .then(result => {console.log(result)})
      //     .catch(error => console.log('error', error));
      // }}>
      //   <input value={username} name='username' type='text' placeholder='username' onChange={(e)=>userChange(e.target.value)}/>
      //   <input value={password} name='password' type='password' placeholder='password' onChange={(e)=>passChange(e.target.value)}/>
      //   <button type='submit'>Login</button>
      //   <span>click <a onClick={(e) => {setLogin(!login)}}>here</a> to sign up instead</span>
      // </form>
      // } else {
      // }


      // const response = async () => {
      //   let value = fetch("http://localhost:3333/api/v1/auth/session", {
      //     // credentials: 'same-origin',
      //     method: 'POST',
      //     headers: {'Content-Type': 'application/json'},
      //     body: JSON.stringify({username: username, password: password})
      // })
      // console.log("made request")
      // return value
      // // etc...
      // }
      // let valuer = response().then((response) => response.json())
      // .then((result) => {
      //     console.log(result);
      //     return result;
      // })
      // .catch((error) => {
      //     console.log(`${error}`);
      // });
      // console.log(valuer)
      // console.log(response())
