import  React, {Fragment, useEffect, useCallback, useState, useRef, Dispatch, SetStateAction } from 'react'
import Cookies from 'universal-cookie';

interface LogoutProps {
    setCookie: (cookie: string, val: string) => void;
    setAuth: Dispatch<SetStateAction<boolean>>;
    setCookieStore: Dispatch<SetStateAction<string>>;
}

var LogoutButton = (props:LogoutProps) => {
    // const cookies = props.cookie
    const setAuth = props.setAuth
    // const setCookie = props.setCookie

    const doLogout = () => {
        props.setCookie('x-access-token', '')
        // document.cookie
        props.setCookieStore('')
    }

    return (
        <>
            <a className="h-10 px-6 py-2 font-semibold rounded-md bg-emerald-500 text-black" href="" onClick={doLogout}>Logout</a>
        </>
    )
}

export default LogoutButton