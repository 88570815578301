import  React, {Fragment, useEffect, useCallback, useState, useRef} from 'react';
// import ReactDOM from "react-dom";

// import { createReactEditorJS } from "react-editor-js";
// import EditorJS from "@editorjs/editorjs";
// import { EDITOR_JS_TOOLS } from "./tools";
import { invoke } from '@tauri-apps/api/tauri'
import { appWindow } from '@tauri-apps/api/window';
import Cookies from 'universal-cookie';
import useAsync from '../functions/useAsync'
// import LogoutButton from '../components/logoutButton';
import LoadingIcon from '../components/LoadingSpinner';
import axios from "axios";
import {
  useQuery,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
// import { Fragment } from "react";
import ReactEditor from '../components/Editor';


const queryClient = new QueryClient();


const EditorView = (props) => {
  const instanceRef = useRef(null)
  const altCookie = props.cookieStore
  const [openNote, setOpenNote] = useState(undefined)
  // async function handleSave() {
  //   const savedData = await instanceRef.current.save();

  //   console.log("savedData", savedData);
  // }
  // await 
  // appWindow.setTitle(`${props.title} | whim Editor`).then((result) => {console.log(result)})
  // invoke('changeTitle', {newTitle:`${props.title} | whim Editor`})
  // const Editor = new EditorJS({holder:"editorjs", tools:{EDITOR_JS_TOOLS}})
  
  // var formdata = new FormData();
  // formdata.append("token",props.cookieStore)

  const getdata = useEffect(() => {
    var cookietoappend = ""
    
    // console.log("cookie xaccess:", props.cookie.get("x-access-token"))
    // console.log("cookie issue stuff:",(props.cookie.get("x-access-token") == undefined ? altCookie : props.cookie.get("x-access-token")) )
    // console.log(`${altCookie}\n${props.cookie.get("x-access-token")}`)
    // console.log("this is cookieStore:",props.cookieStore)
    // if ( altCookie == "") {
    //   console.log("alt cookie == ''")
    //   cookietoappend = props.cookie.get("x-access-token")
    // }
    // if ( props.cookie.get("x-access-token") == "") {
    //   console.log("propscookie == ''")
    //   cookietoappend = altCookie
    // }
    var formdata = JSON.stringify({token:props.cookieStore})
    let retdata = fetch("https://api.whimeditor.com" + props.NoteUrl, {
      credentials: "same-origin",
      headers: {
        Cookie: 'x-access-token=' +  props.cookieStore,
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    })
      .then(async (response) => {
        console.log(response);
        if (response.status !== 200) {
          console.log(await response.text());
          return {};
        } else if (response.status === 200) {
          console.log("response from server was successful");
          // console.log(response.text())
          // console.log("json:",response.json())
          return response.json();
        }
      })
      .then(async (result) => { console.log("opennote was set:", result); setOpenNote(result); console.log("savedopennote:", openNote); return result; })
      .catch(error => console.log('error', error));
    // console.log("retdata:", retdata)
    // console.log("saveddata:", openNote);
    // console.log("altcookie:",altCookie)

  }, [])


  let handleInitialize = () => {
    console.log("handling editorview initilization OpenNote:",openNote)
    if (typeof window !== "undefined"){
      if (openNote !== undefined) {
        console.log("new open Note:",openNote)
        return <ReactEditor cookie={props.cookies} cookieStore={props.cookieStore} content={openNote.content} noteID={openNote.id} title={openNote.title} />
      } else {
        return <LoadingIcon></LoadingIcon>
      }
    } else {
      return <LoadingIcon></LoadingIcon>    
    }
    
  }

  // function getNoteData() {
  //   return useQuery(["posts"], async () => {
  //     console.log(`x-access-token=${props.cookieStore || props.cookie.get("x-access-token")};`)
  //     const { data } = await axios.get(
  //       `https://api.whimeditor.com/${props.NoteUrl}`,{
  //         headers: {
  //           Cookie: `x-access-token=${props.cookieStore || props.cookie.get("x-access-token")};`
  //         }
  //       }
  //     );
  //     return data;
  //   });
  // }

  return (
    <QueryClientProvider client={queryClient}>
      <div style={{width:"100%"}}>
          <div id='editorjs' style={{paddingLeft: "0px",margin:"auto", maxWidth:"60vw"}}> 
            {handleInitialize()}
          </div>
      </div>
    </QueryClientProvider>
  );
};

export default EditorView

// export default function index(params) {
//   useEffect(() => {
//      const editor = new EditorJS({
//         holder: "editorjs",
//      });
//   }, []);
  
//   return (
//      <>
//         <h1>New Note</h1>
//         <div className="Editor" id="editorjs" />
//      </>
//   );
// }
