import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import { Squash as Hamburger } from 'hamburger-react'
// import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import LogoutButton from '../components/LogoutButton';
// import '../css/navbar.css'

// import '@trendmicro/react-sidenav/dist/react-sidenav.css';


const NavMenuView = (props) => {
    const [navToggle, setNavToggle] = useState(false);

    var cookie_post = props.getCookie("x-access-token");
    var formdata = JSON.stringify({ token: cookie_post,  })
    let retdata = fetch("https://api.whimeditor.com/api/space/get_space_from_note", {
        credentials: "same-origin",
        headers: {
            Cookie: 'x-access-token=' + cookie_post,
            'Content-Type': 'application/json'
        },
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    })
        .then(async (response) => {
            console.log(response);
            if (response.status !== 200) {
                console.log(await response.text());
                return {};
            } else if (response.status === 200) {
                console.log("response from server was successful");
                // console.log(response.text())
                // console.log("json:",response.json())
                return response.json();
            }
        })
        .then(async (result) => { console.log("response for was set:", result); return result; })
        .catch(error => console.log('error', error));

    
// h-full
    return (
        <div
            className="top-0 left-0 w-[35vw] p-10 pl-20 text-white bg-emerald-500 sticky rounded-r-lg"
            style={{
                width: navToggle ? '300px' : '60px',
                padding: "0px",
                height: "100vh"
                // color: isActive ? 'white' : '',
            }}
        >
            <Hamburger style={{width:"100%"}} size={30} toggled={navToggle} toggle={setNavToggle} />
            <div className="menuBarItems flex flex-col text-black" style={{display:navToggle ? "flex":"none"}}>
                <a className="h-10 px-6 py-2 font-semibold rounded-md bg-emerald-500 text-black" href="">Space</a>
                <LogoutButton cookie={props.cookies} setAuth={props.setAuth} setCookie={props.setCookie} setCookieStore={props.setCookieStore}></LogoutButton>
            </div>
            {/* <a onClick={() =>{setNavToggle(!navToggle)}}><div className=' w-full'>{togglButton(navToggle)}</div></a> */}
            {/* <h2 className="mt-20 text-4xl font-semibold text-white">I am a sidebar</h2> */}
        </div> 
    )
}

export default NavMenuView



{/*        
            <SideNav
                onSelect={(selected) => {
                    // const to = '/' + selected;
                    // if (location.pathname !== to) {
                    //     history.push(to);
                    // }
                }}
                style={{"background":"#10b981","height":"100%"}}
            >
                <SideNav.Toggle />
                <SideNav.Nav defaultSelected="home">
                    <NavItem eventKey="home">
                        <NavIcon>
                            <i className="fa fa-fw fa-home" style={{ fontSize: '1.75em' }} />
                        </NavIcon>
                        <NavText>
                            Home
                        </NavText>
                    </NavItem>
                    <NavItem eventKey="devices">
                        <NavIcon>
                            <i className="fa fa-fw fa-device" style={{ fontSize: '1.75em' }} />
                        </NavIcon>
                        <NavText>
                            Devices
                            <LogoutButton cookie={props.cookies} setCookie={props.setCookieStore} setAuth={props.setAuth}></LogoutButton>
                        </NavText>
                    </NavItem>
                </SideNav.Nav>
            </SideNav> 
            </div> */}