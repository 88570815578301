import '../css/spinner.css'


const LoadingIcon = (props) => {

    // style={{height:"100vh"}}
    return (
        <div className="spinner-container flex justify-center h-screen" style={{paddingTop:"50vh"}}>
            <div className="loading-spinner">
            </div>
        </div>
    )
}

export default LoadingIcon