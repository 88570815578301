import  React, {Fragment, useEffect, Component, useState, useRef} from 'react'
// import ReactDOM from "react-dom";
import DragDrop from 'editorjs-drag-drop';
import Undo from 'editorjs-undo';
import { createReactEditorJS } from "react-editor-js";
import EditorJS from "@editorjs/editorjs";
import { EDITOR_JS_TOOLS } from "../tools";
import { invoke } from '@tauri-apps/api/tauri'
import { appWindow } from '@tauri-apps/api/window';
import Cookies from 'universal-cookie';
// import { Fragment } from "react";








const ReactEditor = (props) => {
  const EditorJs = createReactEditorJS({
    onReady: () => {
      new Undo({ EditorJS });
      new DragDrop(EditorJS);
    },
  });
  // useEffect(() => {
  //   const EditorJs = createReactEditorJS({
  //     onReady: () => {
  //       new Undo({ EditorJS });
  //       new DragDrop( EditorJS );
  //     },
  //   });
  // }, []);
  // const instanceRef = useRef(null)
  // const [openNote, setOpenNote] = useState({})
  // async function handleSave() {
  //   const savedData = await instanceRef.current.save();

  //   console.log("savedData", savedData);
  // }
  // await 

  // invoke('changeTitle', {newTitle:`${props.title} | whim Editor`})
  // const Editor = new EditorJS({holder:"editorjs", tools:{EDITOR_JS_TOOLS}})
  
  // var formdata = new FormData();
  // formdata.append("token",props.cookie.get("x-access-token"))

  // const getdata = useEffect(() => {
  //   let retdata = fetch("https://api.whimeditor.com/" + props.NoteUrl, {
  //     credentials: "same-origin",
  //     headers: {
  //       Cookie: 'x-access-token=' + props.cookie.get("x-access-token")
  //     },
  //     method: 'POST',
  //     body: formdata,
  //     redirect: 'follow'
  //   })
  //     .then(async (response) => {
  //       console.log(response);
  //       if (response.status != 200) {
  //         console.log(await response.text());
  //         return {};
  //       } else if (response.status == 200) {
  //         console.log("response from server was successful");
  //         return response.json();
  //       }
  //     })
  //     .then(async (result) => { console.log("opennote was set:", result); setOpenNote(result); console.log("savedopennote:", openNote); return result; })
  //     .catch(error => console.log('error', error));
  //   console.log("retdata:", retdata)
  //   console.log("saveddata:", openNote);

  // }, [])
  
  // interface EditorCore {
  //   contructor(): Object

  //   destroy(): Promise<void>
  
  //   clear(): Promise<void>
  
  //   save(): Promise<Object>
  
  //   render(data: Object): Promise<void>
  // }


  const editorCore = React.useRef(null)
  var noteTitle = props.title;
  // console.log(props.title)
  // appWindow.setTitle(`${props.title} | whim Editor`).then((result) => {console.log(result)})

  const handleReady = (editor) => {
    // console.log("inst:",inst)
    console.log("handle Ready",editorCore.current._editorJS)
    // const editor = editorCore.current._editorJS; 
    new Undo({ editor })
    new DragDrop(editor);
  };

  const handleInitialize = React.useCallback(async (instance) => {
    console.log("handling initialization")
    console.log(instance)
    console.log(instance._editorJS)
    console.log("config:",await instance._editorJS.configuration)
    // instance._editorjs.configuration.data = openNote
    editorCore.current = instance
  }, [])

  const handleSave = React.useCallback(async (ref) => {
    // console.log("ref:",ref)
    console.log("Editorjs is saving via handleSave")
    // console.log("Current:",editorCore.current)
    const savedData = await editorCore.current.save();
    console.log("saveddata:",savedData) 
    var formdata = JSON.stringify({token:props.cookieStore,title:noteTitle,noteID:props.noteID,data:savedData})
    let retdata = fetch("https://api.whimeditor.com/api/note/save", {
      credentials: "same-origin",
      headers: {
        Cookie: 'x-access-token=' +  props.cookieStore,
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    })
      .then(async (response) => {
        console.log(response);
        if (response.status !== 200) {
          console.log(await response.text());
          return {};
        } else if (response.status === 200) {
          console.log("response from server was successful");
          // console.log(response.text())
          // console.log("json:",response.json())
          return response.text();
        }
      })
      .then(async (result) => { console.log(result);  })
      .catch(error => console.log('error', error));
  }, [])


  return (
    <div>
      <div id='editorjscontainer'>
      {/* <EditorJS holder="editorjs"  tools={{EDITOR_JS_TOOLS}}></EditorJS> */}
      <EditorJs
        id='editorjs'
        onInitialize={handleInitialize}
        onReady = { handleReady }
        onChange={(inst) => handleSave(inst)}
        tools={EDITOR_JS_TOOLS}
        data={props.content}
      />
      </div>
    </div>
  );
};

 export default ReactEditor

// export default function index(params) {
//   useEffect(() => {
//      const editor = new EditorJS({
//         holder: "editorjs",
//      });
//   }, []);
  
//   return (
//      <>
//         <h1>New Note</h1>
//         <div className="Editor" id="editorjs" />
//      </>
//   );
// }
