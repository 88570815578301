import React, { Fragment, useEffect, useCallback, useState, useRef, Dispatch, SetStateAction } from 'react'
import Cookies from 'universal-cookie';

import EditorView from './EditorView';
import NavMenuView from './NavMenuView';





interface PanelProps {
    cookie: Cookies;
    setCookie: (cookie: string, val: string) => void;
    getCookie: (cookie: string) => string;
    cookieStore: String; 
    setAuth: Dispatch<SetStateAction<boolean>>;
    setCookieStore: Dispatch<SetStateAction<string>>;
}

const PanelView = (props: PanelProps) => {
    const cookies = props.cookie
    // cookies.set('x-access-token', '', { path: '/', secure: true })
    // const setAuth = props.setAuth
    // const setCookie = props.setCookie

    // const [auth, setAuth] = useState(false);
    // const [login, setLogin] = useState(true);
    const [navToggled, setNavToggled] = useState(false);
    const [openNote, setOpenNote] = useState("/api/user/home_page")
    // const [cookieStore, setCookieStore] = useState("/api/user/home_page")
    const [NoteTitle, SetNoteTitle] = useState("")
    const [SpaceTitle, SetSpaceTitle] = useState("")

    var storedCookie = ""

    const setNavState = (check: boolean) => {
        setNavToggled(check)
    }

    const changeNoteView = (noteId: string) => {
        
    }

    return (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
            <NavMenuView cookie={cookies} cookieStore={props.cookieStore} setCookie={props.setCookie} getCookie={props.getCookie} setCookieStore={props.setCookieStore} setAuth={props.setAuth} NoteUrl={openNote} ></NavMenuView>
            <EditorView cookie={cookies} cookieStore={props.cookieStore} setCookie={props.setCookie} getCookie={props.getCookie} NoteUrl={openNote} title={""} />
        </div>
    )
}

export default PanelView






    // let handleState = (value: boolean, login: boolean) => {
    //     console.log(cookies.get("x-access-token"), value, login);
    //     if (!!value || (cookies.get("x-access-token") != "" || cookies.get("x-access-token") != undefined)) {
    //         setCookieStore(cookies.get("x-access-token"));
    //         // <ReactEditor cookie={cookies} NoteUrl={openNote} title={"Home Page"}/>

    //         return (
    //             <div style={{ display: "flex", justifyContent: "space-between" }}>
    //                 <NavMenuView cookie={cookies} cookieStore={cookieStore} setCookieStore={setCookieStore} setAuth={setAuth} NoteUrl={openNote} ></NavMenuView>
    //                 <EditorView cookie={cookies} cookieStore={cookieStore} NoteUrl={openNote} title={""} />
    //             </div>
    //         )
    //     }
    //     else {
    //         return (
    //         <div style={
    //         {
    //             display: "flex",
    //             justifyContent: "center",
    //             flexWrap: "wrap",
    //             flexDirection: "column",
    //             alignItems: "center"
    //         }}>
    //             <AuthHandler cookie={cookies} setCookie={setCookieStore} setAuth={setAuth} pushCookie={setCookieVal} />
    //         </div>
    //         )
    //     }
    // }