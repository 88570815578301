import  React, { useState, Dispatch, SetStateAction } from 'react'
import Cookies from 'universal-cookie';
import axios from 'axios';
import '../App.css';
import '../css/tailwind.css'
// import ReactDOM from "react-dom";
// import { Fragment } from "react";

interface AuthenticationProps {
  cookie:Cookies;
  setAuth: Dispatch<SetStateAction<boolean>>;
  setCookie: Dispatch<SetStateAction<string>>;
  pushCookie: (cookie: string) => void;
}

const AuthView = (props:AuthenticationProps) => {
  const cookies = props.cookie
  const setAuth = props.setAuth
  const setCookie = props.setCookie
  const [error, setError] = useState("")
  const [login, setLogin] = useState(true);
  const [name, setName] = useState("")
  const [username, setUsername] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [password2, setPassword2] = useState("")

// {display:'inline-block',textAlign:'center',verticalAlign:'center'}
// style={{display: "flex", justifyContent: "center",flexWrap:"wrap",flexDirection:"column", alignItems: "center"}}
  return (
    <div className="App bg-emerald-500">
      {(error != '') ? <p className='bg-red-500 text-neutral-50 rounded-b-lg'>{error}</p> : <></>}
      <form
      className='bg-neutral-50 rounded'
      style={{
        position: 'absolute', left: '50%', top: '50%',
        transform: 'translate(-50%, -50%)',
      }} method='post' action='https://api.whimserver.com/auth/login' onSubmit={async (e: React.SyntheticEvent) => {
        console.log("Beginning Request")
        e.preventDefault();
        console.log(e)

        // var formdata = new FormData();
        var formdata = JSON.stringify({username:username,password:password})
        // formdata.append("username", username);
        // formdata.append("password", password);
        if (!login) {
          formdata = JSON.stringify({name:name,email:email,username:username,password:password,password2:password2})
          // formdata.append("name",name)
          // formdata.append("email",email)
          // formdata.append("password2",password2)
        }

        // var requestOptions = {
        //   method: 'POST',
        //   body: formdata,
        //   redirect: 'follow'
        // };
        var url = login ? "https://api.whimeditor.com/auth/login":"https://api.whimeditor.com/auth/register"
        
        // axios.post(url,formdata,{
        //   withCredentials: true,
        //   headers: {
        //     'Content-Type': 'application/json'
        //   },
        // }
        // )
        console.log("formdata:",formdata)
        fetch(url, {
          mode: 'cors',
          credentials: "same-origin",
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: formdata,
          redirect: 'follow'
        })
          .then(async response => {
            console.log(response);
            if (login) {
              if (response.status != 200) {
                setError(await response.text());
              } else if (response.status == 200) {
                setError('');
                return response.json()
              }
            } else {
              if (response.status != 200) {
                setError(await response.text());
              } else if (response.status == 200) {
                setError('');
                setLogin(!login)
              }
            }
          })
          .then(result => {
            console.log(result);
            cookies.set('x-access-token', result.replaceAll("%22", ""), { path: '/', secure: true })
            console.log("-------- setting cookie --------")
            setCookie(result.replaceAll("%22",""))
            console.log("whats set:",result.replaceAll("%22",""))
            console.log("whats the cookie:",cookies.get("x-access-token"))
            setAuth(true);
            props.pushCookie(result.replaceAll("%22",""))
          })
          .catch(error => console.log('error', error));

        // fetch("http://localhost:3333/api/v1/auth/session", {
        //   credentials: "same-origin",
        //   method: 'POST',
        //   body: formdata,
        //   redirect: 'follow'
        // })
        //   .then(response => response.text())
        //   .then(result => {console.log(result)})
        //   .catch(error => console.log('error', error));
      }}>
        <div className='max-w-lg rounded-lg overflow-hidden p-4 shadow-lg bg-white-50 rounded'
          style={{
          display: "flex",
          justifyContent: "center",
          flexWrap:"wrap",
          flexDirection:"column",
          alignItems: "center",
          
          }}>
          {login ? null:<input className=' bg-neutral-50 m-1' style={{display:'inline-block',}} value={name} name='name' type='text' placeholder='name' onChange={(e)=>setName(e.target.value)}/>}
          {login ? null:<input className=' bg-neutral-50 m-1' style={{display:'inline-block'}} value={email} name='email' type='text' placeholder='email' onChange={(e)=>setEmail(e.target.value)}/>}
          <input className=' bg-neutral-50 m-1' style={{display:'inline-block'}} value={username} name='username' type='text' placeholder='username' onChange={(e)=>setUsername(e.target.value)}/>
          <input className=' bg-neutral-50 m-1' style={{display:'inline-block'}} value={password} name='password' type='password' placeholder='password' onChange={(e)=>setPassword(e.target.value)}/>
          {login ? null:<input className=' bg-neutral-50 m-1' style={{display:'inline-block'}} value={password2} name='password2' type='password' placeholder='Confirm password' onChange={(e)=>setPassword2(e.target.value)}/>}
          <br/>
          <button className='bg-emerald-500 p-1 rounded text-teal-50' type='submit'>{login ? "Log in":"Sign up"}</button>
          <br/>
          <span>click <a style={{cursor: 'pointer'}} className='text-emerald-500' onClick={(e) => {setLogin(!login) ;setError('')}}>here</a> to {login ? "sign up":"log in"} instead</span>
        </div>
      </form>
    </div>
  );
};

export default AuthView
